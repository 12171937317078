/*---------- About Classes-----------*/
.accent-border {
  border: var(--accent-color-primary);
  border-width: 0.5rem;
}
.info-card {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 10% 15% 10%;
  border-style: solid;
  border-radius: 15px;
  overflow: hidden;
  align-items: stretch;
}
.info-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.info-body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.info-title {
  background-color: var(--primary-color-dark);
  width: 100%;
  text-align: center;
}

.info-text-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
}

.info-text {
  padding: 0.25rem;
  text-align: center;
  align-self: center;
}

.deploy-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: #1cddffa6;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s, background-color 0.175s;
}
.deploy-text {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: #ffffff;
  font-size: 150%;
  text-shadow: 2px 0px 3px var(--primary-color-dark);
}
.deploy-wrap {
  position: relative;
}

.deploy-wrap:hover .deploy-screen {
  visibility: visible;
  opacity: 1;
}

/*-------------------- Media Breakpoints --------------------*/
/*--- Tablet Layout ---*/
@media all and (min-width: 768px) {
  .accent-border {
    border-style: solid;
    border-width: 1vw;
  }
  .info-card {
    flex-direction: row;
    margin: 0 5% 5% 5%;
  }
  .info-img {
    border-style: none;
    border-right-style: solid;
  }
  .flipped {
    flex-direction: row-reverse;
  }
  .flipped .info-img {
    border-style: none;
    border-left-style: solid;
  }
  .info-text {
    padding: 0;
  }
}

/*--- Desktop Layout ---*/
@media all and (min-width: 1024px) {
  .info-card {
    margin: 0 10% 5% 10%;
  }
  .accent-border {
    border-width: 0.7vw;
  }
}

/*--- 2K Layout ---*/
@media all and (min-width: 1440px) {
  .info-card {
    margin: 0 15% 4% 15%;
  }
  .accent-border {
    border-width: 0.65rem;
  }
}

/*--- 4K Layout ---*/
@media all and (min-width: 2560px) {
  .info-card {
    margin: 0 20% 3% 20%;
  }
}
