/*---------- Header & Footer ----------*/
header,
footer {
  background-color: var(--primary-color-dark);
}
header {
  margin-bottom: 2rem;
}
footer {
  margin-top: 2rem;
}

.trademark {
  margin: 0;
  padding-bottom: 0.5rem;
  text-align: center;
  font-size: 1rem;
  color: var(--text-color-primary);
}
.title-text {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding-top: 1rem;
  font-size: 3.25rem;
  font-family: "Times New Roman", Times, serif;
}
/*----- Navbar Classes -----*/
.nav-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-menu {
  display: flex;
  padding-left: 0;
  padding: 1rem;
  list-style: none;
  align-items: center;
}

.nav-bar ul {
  margin: 0;
}

.nav-link {
  color: var(--text-color-primary);
  text-decoration: none;
  padding: 0 1.25rem 0 0;
  font-size: 1.35rem;
  font-family: "Montserrat";
  text-align: center;
  align-self: center;
  user-select: none;
}
.nav-link:visited {
  color: var(--text-color-primary);
}
.nav-item {
  text-align: center;
}
.nav-item:last-child .nav-link {
  padding-right: 0;
}
.nav-link:hover {
  color: var(--text-color-secondary);
}

/*-------------------- Media Breakpoints --------------------*/
/*--- Tablet size ---*/
@media all and (min-width: 768px) {
  header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 5%;
  }
  .title-text {
    font-size: 3.4rem;
    padding-top: 0;
  }
  .nav-menu {
    padding: 0.75rem 0;
  }
}

/*--- Desktop layout ---*/
@media all and (min-width: 1024px) {
  header {
    padding: 1.25rem 10%;
  }
}
/*--- 2K+ Widths ---*/
@media all and (min-width: 1440px) {
  header {
    padding: 1.25rem 15%;
  }
  .title-text {
    font-size: 4.5rem;
  }
  .nav-menu {
    padding: 1rem 0;
  }
  .nav-link {
    font-size: 1.75rem;
  }
}

/* --- 4K+ Widths --- */
@media all and (min-width: 2560px) {
  header {
    padding: 1.5rem 20%;
  }
  .title-text {
    font-size: 5rem;
  }
  .nav-menu {
    padding: 1.25rem;
  }
  .nav-link {
    font-size: 2rem;
  }
}
