.loader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.loader::before {
  content: "";
  width: 3rem;
  height: 3rem;
  border: 0.5rem solid transparent;
  border-top-color: var(--accent-color-primary);
  border-radius: 50%;
  animation: buffering 1s ease infinite;
}

@keyframes buffering {
  to {
    transform: rotate(2turn);
  }
}
