.svg-inline--fa {
  font-size: 6vw;
}
.logo-text {
  font-size: 6vw;
  margin: 2vw;
}
.logo-sub-text {
  font-size: 5vw;
  color: var(--text-color-secondary);
}
a:hover .logo-sub-text {
  color: var(--accent-color-primary);
}
a:active .logo-sub-text {
  color: var(--contrast-color-primary);
}
.social-line {
  margin: 0 2vw 2vw 2vw;
}
.social-line:first-child {
  margin-top: 2vw;
}

/*-------------------- Media Breakpoints --------------------*/
/*--- Tablet size ---*/
@media all and (min-width: 768px) {
  .svg-inline--fa {
    font-size: 6vw;
  }
  .logo-text {
    font-size: 6vw;
    margin: 0 2.5vw;
  }
  .logo-sub-text {
    font-size: 2.5vw;
  }
}

/*--- Desktop size ---*/
@media all and (min-width: 1024px) {
  .svg-inline--fa {
    font-size: 5vw;
  }
  .logo-text {
    font-size: 5vw;
    margin: 0 2.5vw;
  }
  .logo-sub-text {
    font-size: 2vw;
  }
}

/*--- 2K+ Widths ---*/
@media all and (min-width: 1440px) {
  .svg-inline--fa {
    font-size: 4.5vw;
  }
  .logo-text {
    font-size: 4.5vw;
    margin: 0 2vw;
  }
  .logo-sub-text {
    font-size: 1.5vw;
  }
}

/*--- 4K+ Widths ---*/
@media all and (min-width: 2560px) {
  .svg-inline--fa {
    font-size: 3vw;
  }
  .logo-text {
    font-size: 3vw;
    margin: 0 1.5vw;
  }
  .logo-sub-text {
    font-size: 1vw;
  }
}
