/*---------- Color Variables ----------*/
:root {
  --primary-color-dark: #381648;
  --primary-color: #7a468e;
  --text-color-primary: #e0f7ff;
  --text-color-secondary: #d4c6ee;
  --accent-color-primary: #1cddff;
  --accent-color-light: #86fff5;
  --contrast-color-primary: #e40066;
}

/*---------- Default Styles ----------*/
body {
  color: var(--text-color-primary);
  background-color: var(--primary-color);
  margin: 0;
  padding: 0;
  font-family: arial, sans-serif;
  font-size: 4.5vw;
  min-width: fit-content;
}
img {
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h2 {
  font-size: 6vw;
  padding: 1vw;
}

p {
  margin: 2vw;
}

/*---------- Default Link Styles ----------*/
a:link,
a:visited {
  color: #ffffff;
}
a:hover {
  color: var(--accent-color-primary);
}
a:active {
  color: var(--contrast-color-primary);
}

/*---------- Proxy Links ----------*/
.clickable {
  cursor: pointer;
  user-select: none;
}
.clickable:hover {
  color: var(--text-color-secondary);
}

/* -----  Flex classes ----- */
.flex-single {
  flex: 1;
}

.flex-double {
  flex: 1;
}

/*-------------------- Media Breakpoints --------------------*/
/*--- Tablet/Desktop size ---*/
@media all and (min-width: 768px) {
  body {
    font-size: 1.75vw;
  }
  h2 {
    font-size: 4vw;
    padding: 0.2rem;
  }
  p {
    margin: 0.75rem;
  }
}

/*--- Desktop View ---*/
@media all and (min-width: 1024px) {
  body {
    font-size: 1.4vw;
  }
  h2 {
    font-size: 3vw;
  }
  .flex-double {
    flex: 2;
  }
}

/*--- 2k Desktop ---*/
@media all and (min-width: 1440px) {
  body {
    font-size: 1.25vw;
  }
  h2 {
    font-size: 2.5vw;
  }
}

/*--- 4K Desktop ---*/
@media all and (min-width: 2560px) {
  body {
    font-size: 1vw;
  }
  h2 {
    font-size: 2vw;
  }
}
